


























import { Component, Vue } from 'vue-property-decorator'
import globalMixins from '@/mixins/global.ts'
import bus from '@/common/bus'
import personalPassword from '@/components/personal/password.vue'
import personalInfo from '@/components/personal/info.vue'
import { Getter, Action } from 'vuex-class'

@Component({
  components: {
    personalInfo,
    personalPassword
  },
  mixins: [globalMixins]
})

export default class PersonalIndex extends Vue<globalMixins> {
  active: string = 'info'
  @Getter('account') account: Object
  @Action('profile') profile: Function
  @Action('updatePassword') updatePassword: Function
  @Action('profileUpdate') profileUpdate: Function
  get info (): any[] {
    let arr: any[] = []
    arr.push(this.account)
    return arr
  }
  created () {
    this.profile()
  }
  updatePwd (info) {
    let params = JSON.parse(info)
    this.updatePassword({ params }).then(async (res) => {
      if (res.success === true) {
        await this.successMsg('修改密码成功！')
        await this.$router.push('/')
      } else {
        this.errorMsg('修改密码失败！')
      }
    }).catch((error: any) => {
      this.errorMsg('修改密码失败！')
    })
  }
  doDetail (info) {
    let params = JSON.parse(info)
    this.profileUpdate({ params }).then(() => {
      this.successMsg('个人信息修改成功!')
      this.profile().then(() => {
        localStorage.setItem('account', JSON.stringify(this.account))
        bus.$emit('accountChange')
      })
    }).catch((err) => {
      this.errorMsg('个人信息修改失败!')
    })
  }
}
